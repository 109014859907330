import moment from "moment/moment";

class UtilsService {
  public static removeEmptyChildrens(items) {
    items.forEach((item) => {
      if (item.childrens.length === 0) {
        delete item.childrens;
      } else {
        UtilsService.removeEmptyChildrens(item.childrens );
      }
    });
    return items;
  }

  public static formatAmount(amount) {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
    return formatter.format(amount);
  }

  public static formatDateTime = (value) => {
    return value === undefined
      ? ""
      : moment(value).format("DD/MM/YYYY hh:mm a");
  };
}

export default UtilsService;
