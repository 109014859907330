import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "card-header pt-3" }
const _hoisted_2 = { class: "card-title d-flex flex-column" }
const _hoisted_3 = { class: "fs-2hx fw-bold text-white me-2 lh-1 ls-n2" }
const _hoisted_4 = { class: "text-white opacity-50 pt-1 fw-semibold fs-6" }
const _hoisted_5 = { class: "card-body _d-flex _align-items-end pt-10" }
const _hoisted_6 = {
  key: 0,
  class: "d-flex align-items-center flex-column mt-3 w-100"
}
const _hoisted_7 = { class: "d-flex justify-content-between fw-bold fs-6 text-white opacity-50 w-100 mt-auto mb-2" }
const _hoisted_8 = { class: "h-8px mx-3 w-100 bg-white bg-opacity-50 rounded" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end", _ctx.className]),
    style: _normalizeStyle({ backgroundColor: _ctx.bgColor, backgroundImage: `url('${_ctx.bgImage}')` })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.data.total), 1),
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.description), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.detail, (item, i) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
          (item.total > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("span", null, _toDisplayString(item.total) + " " + _toDisplayString(item.name), 1),
                  _createElementVNode("span", null, _toDisplayString(_ctx.calculateItemPercent(item.total)) + "%", 1)
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", {
                    class: "bg-white rounded h-8px",
                    role: "progressbar",
                    style: _normalizeStyle({
                  'width': _ctx.calculateItemPercent(item.total) + '%',
                }),
                    "aria-valuenow": "50",
                    "aria-valuemin": "0",
                    "aria-valuemax": "100"
                  }, null, 4)
                ])
              ]))
            : _createCommentVNode("", true)
        ], 64))
      }), 128))
    ])
  ], 6)), [
    [_directive_loading, _ctx.loading]
  ])
}