
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { Order } from "@/core/model/Orders";
import { Actions } from "@/store/enums/StoreEnums";
import { displayErrors } from "@/core/helpers/errors";
import UtilsService from "@/core/services/UtilsService";
import SearchInput from "@/components/SearchInput.vue";

interface SummaryMethodProps<T = Order> {
  columns: [];
  data: T[];
}

export default defineComponent({
  name: "widget-stat3",
  components: { SearchInput },
  watch: {
    date_from: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal != oldVal && newVal != undefined && newVal != "") {
          this.loadData();
        }
      },
    },
    mutual: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal != oldVal && newVal != undefined) {
          this.loadData();
        }
      },
    },
  },
  props: {
    mutual: { type: String, required: false },
    date_from: { type: String, required: true },
    date_to: { type: String, required: true },
  },
  setup(props) {
    const loading = ref(false);
    const store = useStore();
    const rowsPerPage = ref(20);
    const currentPage = ref(1);
    const total = ref(0);
    const tableData = ref<Array<Order>>([]);
    const search = ref<string>("");
    let sortOrder = "asc";
    let sortField = "name";
    const pageSizes = ref<Array<number>>([20, 30, 50, 100]);
    const util = UtilsService;

    onMounted(() => {
      //
    });

    const loadData = () => {
      if(getDateFrom() == "" || getDateTo() == "") return;

      const params = {
        page_number: currentPage.value,
        page_size: rowsPerPage.value,
        sort_order: sortOrder,
        sort_field: sortField,
        search: search.value,
        filter_mutual: getMutual(),
        filter_date_from: getDateFrom(),
        filter_date_to: getDateTo(),
      };
      loading.value = true;
      store
        .dispatch(Actions.ORDER_LIST, params)
        .then(() => {
          store.state.OrdersModule.list.items.forEach((item) => {
            item.created_at = UtilsService.formatDateTime(item.created_at);
          });

          tableData.value.splice(
            0,
            tableData.value.length,
            ...store.state.OrdersModule.list.items
          );
          loading.value = false;
          rowsPerPage.value = store.state.OrdersModule.list.limit;
          currentPage.value = store.state.OrdersModule.list.page;
          total.value = store.state.OrdersModule.list.total;
        })
        .catch(() => {
          displayErrors(store, "OrdersModule");
          loading.value = false;
        });
    };

    const getDateFrom = () => {
      return props.date_from;
    };

    const getDateTo = () => {
      return props.date_to;
    };

    const getMutual = () => {
      return props.mutual;
    };

    const changePagination = (page) => {
      currentPage.value = page;
      loadData();
    };

    const pageSizeChange = (size) => {
      rowsPerPage.value = size;
      loadData();
    };

    const sortChange = (data) => {
      sortField = data.prop;
      sortOrder = data.order == "ascending" ? "asc" : "desc";
      loadData();
    };

    const searchItems = (value: string) => {
      search.value = value;
      loadData();
    };

    const getSummary = (param: SummaryMethodProps) => {
      const { columns, data } = param;
      const sums: string[] = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "TOTAL";
          return;
        }
        if (data.length > 0) {
          const values = data.map((item) => Number(item[column["property"]]));
          if (!values.every((value) => Number.isNaN(value))) {
            sums[index] = util.formatAmount(
              values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!Number.isNaN(value)) {
                  return prev + curr;
                } else {
                  return prev;
                }
              }, 0)
            );
          } else {
            sums[index] = "";
          }
        }
      });

      return sums;
    };

    return {
      loading,
      rowsPerPage,
      currentPage,
      total,
      tableData,
      loadData,
      changePagination,
      pageSizeChange,
      pageSizes,
      util,
      store,
      search,
      sortChange,
      getSummary,
      searchItems
    };
  },
});
