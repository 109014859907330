
import { defineComponent, onMounted, ref } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { displayErrors } from "@/core/helpers/errors";
import { useStore } from "vuex";
import { DataStat1, DetailStat1 } from "@/core/model/DataStat1";
import UtilsService from "@/core/services/UtilsService";

export default defineComponent({
  name: "widget-stat1",
  components: {},
  watch: {
    date_from: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal != oldVal && newVal != undefined && newVal != "") {
          this.initWidget();
        }
      },
    },
    mutual: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal != oldVal && newVal != undefined) {
          this.initWidget();
        }
      },
    },
  },
  props: {
    className: { type: String, required: false },
    chartSize: { type: Number, required: true },
    mutual: { type: String, required: false },
    date_from: { type: String, required: true },
    date_to: { type: String, required: true },
  },
  setup(props, { expose }) {
    const loading = ref(false);
    const store = useStore();
    const data = ref<DataStat1>({
      total: 0,
      detail: [],
    } as DataStat1);
    const util = UtilsService;

    onMounted(() => {
      expose();
    });

    const initWidget = () => {
      loadData();
    };

    const initChart = () => {
      var el = document.getElementById("widget_stat_1_chart");

      if (!el) {
        return;
      }

      var options = {
        size: el.getAttribute("data-kt-size")
          ? parseInt(el.getAttribute("data-kt-size") as string)
          : 70,
        lineWidth: el.getAttribute("data-kt-line")
          ? parseInt(el.getAttribute("data-kt-line") as string)
          : 11,
        rotate: el.getAttribute("data-kt-rotate")
          ? parseInt(el.getAttribute("data-kt-rotate") as string)
          : 145,
        //percent:  el.getAttribute('data-kt-percent') ,
      };

      var canvas = document.createElement("canvas");
      var span = document.createElement("span");

      var ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
      canvas.width = canvas.height = options.size;

      el.appendChild(span);
      el.appendChild(canvas);

      ctx.translate(options.size / 2, options.size / 2); // change center
      ctx.rotate((-1 / 2 + options.rotate / 180) * Math.PI); // rotate -90 deg

      //imd = ctx.getImageData(0, 0, 240, 240);
      var radius = (options.size - options.lineWidth) / 2;

      var drawCircle = function (
        color: string,
        lineWidth: number,
        percent: number
      ) {
        if (percent == 0) {
          return;
        }
        percent = Math.min(Math.max(0, percent || 1), 1);
        ctx.beginPath();
        ctx.arc(0, 0, radius, 0, Math.PI * 2 * percent, false);
        ctx.strokeStyle = color;
        ctx.lineCap = "round"; // butt, round or square
        ctx.lineWidth = lineWidth;
        ctx.stroke();
      };

      // Init
      const lines = [] as Array<DetailStat1>;
      store.state.DashboardModule.statOrderIncome.detail.forEach(
        (item: DetailStat1) => {
          lines.push(item);
        }
      );
      lines.sort((a, b) => {
        return b.total - a.total;
      });
      drawCircle("#f0f0f0", options.lineWidth, 100 / 100);
      lines.forEach((item) => {
        drawCircle(item.bgcolor, options.lineWidth, calculateItemPercent(item));
      });
    };

    const calculateItemPercent = (item: any) => {
      if (item.total == 0) return 0;
      const total = store.state.DashboardModule.statOrderIncome.total;
      const percent = (item.total * 100) / total / 100;
      return percent;
    };

    const resetChart = () => {
      var el = document.getElementById("widget_stat_1_chart");
      if (!el) {
        return;
      }
      el.innerHTML = "";
      initChart();
    };

    const loadData = () => {
      if(getDateFrom() == "" || getDateTo() == "") return;

      const params = {
        mutual: getMutual(),
        date_from: getDateFrom(),
        date_to: getDateTo(),
      };
      loading.value = true;
      store
        .dispatch(Actions.DB_ORDERS_INCOME, params)
        .then(() => {
          data.value = store.state.DashboardModule.statOrderIncome;
          resetChart();
          loading.value = false;
        })
        .catch(() => {
          displayErrors(store, "DashboardModule");
          loading.value = false;
        });
    };

    //return prop.date_from to html view
    const getDateFrom = () => {
      return props.date_from;
    };

    const getDateTo = () => {
      return props.date_to;
    };

    const getMutual = () => {
      return props.mutual;
    };

    return {
      getDateFrom,
      getDateTo,
      getMutual,
      loading,
      data,
      initWidget,
      util,
    };
  },
});
