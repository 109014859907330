
import { defineComponent, onMounted, ref } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { displayErrors } from "@/core/helpers/errors";
import { useStore } from "vuex";
import { DataStat1, DetailStat1 } from "@/core/model/DataStat1";
import UtilsService from "@/core/services/UtilsService";

export default defineComponent({
  name: "widget-stat2",
  components: {},
  watch: {
    date_from: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal != oldVal && newVal != undefined && newVal != "") {
          this.initWidget();
        }
      },
    },
    mutual: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal != oldVal && newVal != undefined) {
          this.initWidget();
        }
      },
    },
  },
  props: {
    className: { type: String, required: false },
    bgColor: { type: String, required: false, default: "#080655" },
    bgImage: { type: String, required: false },
    description: { type: String, required: true },
    mutual: { type: String, required: false },
    date_from: { type: String, required: true },
    date_to: { type: String, required: true },
  },
  setup(props) {
    const loading = ref(false);
    const store = useStore();
    const data = ref<DataStat1>({
      total: 0,
      detail: [],
    } as DataStat1);
    const util = UtilsService;

    onMounted(() => {
      //initChart();
    });

    const initWidget = () => {
      loadData();
    };

    const loadData = () => {
      if(getDateFrom() == "" || getDateTo() == "") return;

      const params = {
        mutual: getMutual(),
        date_from: getDateFrom(),
        date_to: getDateTo(),
      };
      loading.value = true;
      store
        .dispatch(Actions.DB_ORDERS_COUNT, params)
        .then(() => {
          data.value = store.state.DashboardModule.statOrderCount;
          loading.value = false;
        })
        .catch(() => {
          displayErrors(store, "DashboardModule");
          loading.value = false;
        });
    };

    //return prop.date_from to html view
    const getDateFrom = () => {
      return props.date_from;
    };

    const getDateTo = () => {
      return props.date_to;
    };

    const getMutual = () => {
      return props.mutual;
    };

    const calculateItemPercent = (item_total: any) => {
      if (item_total.total == 0) return 0;
      const total = store.state.DashboardModule.statOrderCount.total;
      const percent = (item_total * 100) / total;
      return percent.toFixed(2);
    };

    return {
      getDateFrom,
      getDateTo,
      getMutual,
      loading,
      data,
      initWidget,
      util,
      calculateItemPercent,
    };
  },
});
