import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!

  return (_openBlock(), _createBlock(_component_el_input, {
    disabled: _ctx.loading,
    placeholder: _ctx.placeholder,
    "prefix-icon": _ctx.Search,
    size: _ctx.getSize(),
    class: _normalizeClass(_ctx.classes),
    clearable: _ctx.clearable,
    modelValue: _ctx.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
    style: _normalizeStyle({
      'width': _ctx.width
    }),
    onInput: _ctx.changeValue
  }, null, 8, ["disabled", "placeholder", "prefix-icon", "size", "class", "clearable", "modelValue", "style", "onInput"]))
}