
import { defineComponent, ref } from "vue";
import { Search } from "@element-plus/icons-vue";

enum Sizes {
  DEFAULT = "default",
  LARGE = "large",
  SMALL = "small",
}
export default defineComponent({
  name: "search-input",
  emit: ["change-search"],
  props: {
    classes: { type: String },
    placeholder: { type: String, default: "Buscar..." },
    width: { type: String, default: "220px" },
    size: {
      type: String,
      default: Sizes.DEFAULT,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  setup(props, {emit}) {
    const value = ref("");
    let timerSearch: number | null = null;

    const getSize = () => {
      return props.size as Sizes;
    };

    const changeValue = (value: string) => {
      if (timerSearch) {
        clearTimeout(timerSearch);
        timerSearch = null;
      }
      timerSearch = setTimeout(() => {
        emit('change-search', value);
      }, 800);
    };

    return {
      getSize,
      Search,
      value,
      changeValue
    };
  },
});
